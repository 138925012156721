.layout {
  background-color: #f5f7f9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow-y: auto;
}

.init_env_header {
  position: fixed;
  padding: 15px;
  height: 110px;
  background-color: #ffffff;
  /* Card-shadow-hover */
  box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
  z-index: 99;
}

.x_button {
  content: 'x';
  height: 30px;
  width: 30px;
  border: 1px solid #dcdce4;
  border-radius: 4px;
}

.container {
  margin-top: 150px;
  margin-right: 10px;
  margin-left: 50px;
  height: 100vh;
}
.image_viewer_card {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;

  :global(.ant-card-body) {
    padding: 24px;
    background-color: #eaeaef;
  }
}

.env_flow {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;
  height: 100%;
}

.divider {
  height: 30px;

  /* Neutral/400 */

  border: 1px solid #a5a5ba;
}

.container1 {
  width: 25%;
}

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v_stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.step .line {
  top: 26px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 1px solid #a5a5ba;
}
.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
  white-space: nowrap;
  .small_text {
    color: #666687;
  }
}

.imagesBox {
  border: none;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

.imagesBox img {
  width: 100%;
}

.invertColor {
  filter: invert(100%);
}
