.layout {
  background-color: #f5f7f9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow-y: auto;
  :global(.ant-upload.ant-upload-drag) {
    height: 285px;
  }
}

.init_env_header {
  position: fixed;
  padding: 15px;
  height: 110px;
  background-color: #ffffff;
  /* Card-shadow-hover */
  box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
  z-index: 999;
  top: 0px !important;
}

.x_button {
  content: 'x';
  height: 30px;
  width: 30px;
  border: 1px solid #dcdce4;
  border-radius: 4px;
}

.container {
  margin: 150px;
  height: 100vh;
}

.badge {
  :global(.ant-badge-count) {
    background-color: black;
  }
}

:global(.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra) {
  margin: -2px 0 0 15px;
  color: #32324d;
  font-size: 20px;
  font-weight: 700;
}
:global(.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover) {
  border-color: #1cb697;
}

.upload > div > div {
  width: 100% !important;
  height: 280px !important;
}

// .upload_img > div > div{
//   width: 40% !important;
//   height: 200px !important;
// }

.upload_drag_text {
  margin-top: 97px !important;
  margin-left: -115px !important;
  font-size: 13px;
}

.upload_or_text {
  margin-top: 130px !important;
  margin-left: -95px !important;
  font-size: 13px;
}

.upload_button_text {
  margin-top: 209px !important;
  margin-left: -46px !important;
  font-size: 13px;
}

.upload_drag_img {
  width: 5% !important;
}

.vertical_line::before {
  border-left: 6px solid green;
  height: 100%;
}

.upload_img > div > div {
  width: 250px !important;
  height: 280px !important;
  margin-right: 20px;
}

.delete {
  border: 1px solid #dcdce4;
  padding: 4px;

  border-radius: 4px;
}

.upgrade {
  display: block;
}

:global(.ant-upload.ant-upload-select-picture-card:hover) {
  border-color: #1cb697;
}

.delete_icon {
  border: 1px solid #dcdce4;
  height: fit-content;
  width: auto;
  padding: 12px;
  border-radius: 4px;
}

.span_add {
  color: #2c5cc5;
}

.text_area {
  width: 800px;
}
.container1 {
  width: 25%;
}

.step {
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v_stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.step .line {
  top: 26px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 1px solid #1717c6;
}
.step:last-child .line {
  border-left: 1px solid rgb(6, 4, 4);
  z-index: -1; /* behind the circle to completely hide */
}
.approverIndex > div > div {
  position: absolute;
}

.approverdisIndex > div > div > div > input {
  cursor: not-allowed !important;
  pointer-events: none;
}
.approverdisIndex > div > div > div > div {
  display: none;
}
.approverdisIndex > div > div {
  background-color: #ebeff3 !important;
  border: 2px #ebeff3 !important;
}

.approverdisIndex > div > div > div > ul {
  display: none;
}

.circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  margin-top: -3px;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.line {
  top: 30px;
  left: 28px;
  height: 100%;
  position: absolute;
  border-left: 1px solid #a5a5ba;
}

.approver_line:last-child.line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}
.nodragndrop {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 8rem 1rem 1rem 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  :global(.ant-form-item-label > label) {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  :global(.ant-form-item-label > label) {
    font-size: 10px;
  }
}
