.container {
  height: 100vh;
  .left_outer_container {
    background: white;
    height: 100%;
    padding: 60px 50px;
    .left_text {
      width: 517px;
      height: 42px;
      position: absolute;
      left: 3.47%;
      right: 60.62%;
      top: 7.5%;
      bottom: 83.73%;

      font-family: 'SF Pro Text';
      font-style: normal;
      font-weight: 700;
      font-size: 31px;
      line-height: 42px;
      /* identical to box height, or 135% */

      letter-spacing: -0.02em;

      /* Primary/left nav */

      color: #1e206a;
    }
    .left_text_2 {
      position: absolute;
      left: 3.82%;
      right: 49.72%;
      top: 14.33%;
      bottom: 75.47%;
      font-family: 'SF Pro Text';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #1e206a;
      width: 669px;
      height: 54px;
    }
    .left_image {
      position: absolute;
      width: 574px;
      height: 100.26px;
      left: 92px;
      top: 154px;
    }
  }
}
