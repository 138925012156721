.breadcrub_container{
    margin-top: -50px;
    margin-left: -6%;
    margin-right: -6%;
    background-color: white;
    height: auto;
    padding: 25px;
}

.settings_card{
    background: #FFFFFF;
/* Card-shadow */

box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
border-radius: 10px;
}