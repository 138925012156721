.right_outer_container {
  height: 100%;
  width: 60%;
  margin: auto;
  .right_image {
    width: 50%;
    margin: 0 auto;
  }
}
.form_width {
  width: 100% !important;
}
.left_outer_container {
  background: white;
  height: 100%;
  padding: 60px 50px;
}
.secondary_button {
  color: white !important;
  background: #1cb697 !important;
  border-radius: 4px;
  border: 1px solid #1cb697 !important ;
}

.image_holder {
  height: 100vh;
  position: fixed;
}

.rect_image {
  right: 0px;
  bottom: 0;
  position: fixed;
  width: 35%;
}

.overlay_image {
  position: fixed;
  right: 0px;
  bottom: 160px;
  width: 30%;
}

.main_header {
  font-weight: 800;
  font-size: 60px;
  line-height: 65px;
  /* or 130% */

  letter-spacing: -0.02em;

  color: #1c4e9d;
}

.row_container {
  width: 250px;
  height: 250px;
  background: #f0f0ff;
  border-radius: 5px;
  padding: 30px;
  margin-right: 10px;
}
@media only screen and (max-width: 1150px) {
  .row_container {
    width: 170px;
    height: 220px;
    font-size: 11px;
  }
  .rect_image {
    width: 30%;
  }
  .main_header {
    width: 98%;
    font-size: 41px;
  }
}
.tata_logo {
  right: 0px;
  top: 0;
  z-index: 1;
  position: fixed;
}

.left_screen {
  z-index: 8;
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
@media (min-width: 768px) {
  .left_screen {
    float: left;
    /*position: fixed;*/
    left: 0;
    top: 0;
    height: 100%;
    width: 60%;
  }
}

.right_screen {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
@media (min-width: 768px) {
  .right_screen {
    float: right;
    /*position: fixed;*/
    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
  }
}

@media (max-width: 768px) {
  .right_screen {
    display: none;
    float: right;
    /*position: fixed;*/
    right: 0;
    top: 0;
    height: 100%;
    width: 35%;
  }
}

@media only screen and (min-width: 2260px) {
  .right_logo {
    height: 100px;
  }
  .left_logo {
    height: 140px;
    width: 400px;
  }
  .secondary_button {
    width: 400px;
    font-size: 25px;
    height: 85px;
  }
  .row_container {
    width: 450px;
    height: 500px;
    font-size: 32px;
    margin-right: 25px;
    margin-top: 5rem !important;
    margin-bottom: 4rem !important;
    img {
      height: 100px;
      width: 100px;
    }
  }
  .rect_image {
    width: 30%;
  }
  .main_header {
    width: 98%;
    font-size: 120px;
    line-height: initial;
  }
}
.tata_logo {
  right: 0px;
  top: 0;
  z-index: 1;
  position: fixed;
}

@media only screen and (max-width: 1300px) {
  .row_container {
    width: 200px;
    height: 220px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1024px) {
  .row_container {
    width: 175px;
    height: 220px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1560px) {
  .main_header {
    font-size: 53px;
  }
}

@media only screen and (max-width: 1348px) {
  .main_header {
    font-size: 50px;
  }
}
