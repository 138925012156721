.upper_card_container {
  background-color: #4945ff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 10px;
  height: auto;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 15px;
  position: relative;
  width: auto;
}

.lower_card_container {
  padding-top: 80px;
  padding-bottom: 110px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 10px;
  height: auto;
  width: auto;
}

:global(.ant-select-item-option-content) {
  white-space: normal;
  word-break: break-word;
}

.left_greeting {
  position: relative;
  width: 30%;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #ffffff;
  float: left;
}

.vertical_divider {
  float: right;
  border: 1px dashed #cfd7df;
  height: 230.41px;
}

.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.caption {
  padding: 20px 0 15px 0;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #333333;

  display: block;
}

.description {
  display: block;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  text-align: center;

  color: #666666;
  padding-bottom: 15px;
}

.lower_card_button {
  color: white;

  background-color: #4945ff;
  width: auto;
  height: 39px;
}

.upper_card_right_side {
  position: relative;
  white-space: nowrap;
  color: white;
}
.upper_card_divider {
  margin-right: 40px;
  width: 2px;
  height: 75px;
  background: #241ff9;
}
.upper_card_right_side_b {
  position: relative;
  white-space: nowrap;
  color: white;
  font-size: 40px;
  font-weight: 600;
}

.left_triangle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 6px 0 0 0;
}

.full_square {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}

.hr_dots {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 35px 0px 0px 85px;
}

.flipped_triangle1 {
  position: absolute;
  float: right;
  top: 0;
  bottom: 0;
  right: 30px;
}

.flipped_triangle2 {
  position: absolute;
  float: right;
  top: 0;
  bottom: 0;
  right: 50px;
}

.vr_dots {
  position: absolute;
  float: right;
  top: 40px;
  bottom: 0;
  right: 30px;
}

.bt_dots {
  bottom: 6px;
  left: 50%;
  position: absolute;
}
