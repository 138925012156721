.right_outer_container {
  height: 100%;
  width: 60%;
  margin: auto;
  .right_image {
    width: 50%;
    margin: 0 auto;
  }
}
.secondary_button {
  color: white !important;
  background: #1cb697 !important;
  border-radius: 4px;
  border: 1px solid #1cb697 !important ;
}
