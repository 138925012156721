.layout {
  background-color: #f5f7f9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow-y: auto;
  :global(.ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box) {
    padding: 0px;
  }

  .subSteps {
    margin-top: 5px;
    :global(.ant-steps-vertical
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail::after) {
      margin-left: -6px;
    }
  }

  :global(.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon) {
    margin-right: 8px;
  }

  :global(.ant-steps-small
      .ant-steps-item-custom
      .ant-steps-item-icon
      > .ant-steps-icon) {
    line-height: 15px;
  }

  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 2px 8px;
    color: #52c41a;
  }
}

.init_env_header {
  position: fixed;
  padding: 15px;
  height: auto;
  background-color: #ffffff;
  /* Card-shadow-hover */
  box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
  z-index: 99;
}

.x_button {
  content: 'x';
  height: 30px;
  width: 30px;
  border: 1px solid #dcdce4;
  border-radius: 4px;
}

.container {
  margin-top: 180px;
  margin-right: 2%;
  margin-left: 2%;
  height: 100vh;
}

@media (max-width: 480px) {
  .container {
    margin-top: 200px;
  }
}

.image_viewer_card {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;

  :global(.ant-card-body) {
    padding: 24px;
    background-color: #eaeaef;
  }

  :global(.rpv-core__inner-page) {
    background-color: #eaeaef;
  }
  :global(.rpv-open__input-wrapper) {
    display: none;
  }

  :global(.ant-card-head-title) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    :global(.ant-card-head-title) {
      font-size: 16px;
    }
  }
}

.env_flow {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;
  height: 100%;
  margin-left: -19px !important;
}

.divider {
  height: 30px;

  /* Neutral/400 */

  border: 1px solid #a5a5ba;
}

.container1 {
  width: 100%;
}

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v_stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.step .line {
  top: 26px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 1px solid #a5a5ba;
}
.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;

  .small_text {
    color: #666687;
    font-size: 9px !important;
    // margin-left:-10px !important;
  }
}

.imagesBox {
  border: none;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

.imagesBox img {
  width: 100%;
}

.invertColor {
  filter: invert(100%);
}

.document_viewer {
  display: table-row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.document_viewer_page > div {
  height: 10px !important;
  width: 100%;
  /* height: auto !important; */
}

:global(.react-pdf__Page__canvas) {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.image_viewer_card {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;
}

.att_bg {
  padding: 10px;
  background-color: #ffffff;
}
