.breadcrub_container {
  margin-top: -50px;
  margin-left: -6%;
  margin-right: -6%;
  background-color: white;
  height: auto;
  padding: 25px;
}

.createdSearch {
  :global(.ant-col-xl-7) {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tag_unmapped:hover {
  transition: all ease 0.5s;
  transform: scale(1.1);
  font-size: 1;
  background-color: red;
  color: white;
}

.custom_table {
  :global(.ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
    height: 0;
  }
  :global(.ant-table-thead > tr > th) {
    position: relative;
    font-family: 'SFProText Bold';
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    background: #fafafa;
    /*  border-bottom: 10px solid #dfdede; */

    line-height: 16px;
    color: #212134;
  }

  /* :global(.ant-table-tbody > tr > td) {
    border-bottom: 10px solid #dfdede;
  } */
  :global(.ant-table table) {
    text-align: left;
  }
}
