.heading {
  position: absolute;
  width: 259px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #212134;
}
.empty_state_text {
  margin-left: -64px;
}

.empty_state {
  margin-left: 40%;
}

.empty_image {
  width: 199.1px;
  height: 176.23px;
  margin-top: 65px;
}

.card_title {
  font-weight: 900;

  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Neutral/800 */

  color: #32324d;
}

.icon_color {
  color: #666687;
}

.icon_color_last {
  color: #666687;
  :global(.ant-checkbox-inner) {
    width: 20px;
    height: 20px;
  }
  svg {
    margin-bottom: 2px;
  }
  .down_button svg {
    margin-bottom: 0;
  }
  svg:hover {
    color: #4945ff;
  }
}

.use_button {
  width: 84px;
}

.template_card {
  background: #ffffff;
  /* Neutral/150 */

  border: 1px solid #eaeaef;
  /* Card-shadow-hover */

  box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
  border-radius: 5px;
}

.use_button {
  margin: 0 auto;
  padding: 0;
  display: inline-block;

  text-align: center;
  width: 84px;
  height: 32px;
}
.down_button {
  margin: 0 auto;
  padding: 0;
  display: inline-block;

  text-align: center;
  width: 32px;
  height: 32px;
}

:global(.ant-rate) {
  svg {
    margin-bottom: -3px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum';
    display: inline-block;

    padding: 0;

    font-size: 20px;
    line-height: unset;
    list-style: none;
    outline: none;
  }
}
.not_allowed {
  font-size: 22px !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  color: #c1c1c5 !important;
}

.allowed {
  font-size: 22px !important;
}

.disabled_badge {
  display: none;
}
.badge_color {
  color: #dc3545;
  background: #dc3545;
}
.badge_fav_color {
  color: #28a745;
  background: #28a745;
}
