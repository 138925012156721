.custom_modal_contianer {
  :global(.ant-modal-content) {
    height: 100%;
    :global(.ant-modal-close) {
      background: #ffffff;
      border: 1px solid #dcdce4;
      box-sizing: border-box;
      border-radius: 4px;
      height: 32px;
      width: 32px;
      top: 11px;
      right: 11px;
      :global(.ant-modal-close-x) {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 14px;
      }
    }
    :global(.ant-modal-header) {
      border-bottom: none;
      font-family: 'SFProText Bold';
    }
    &.custom_footer :global(.ant-modal-footer) {
      border-top: none;
    }
  }
}
.custom_modal_contianer1 {
  :global(.ant-modal-content) {
    height: 100%;
    :global(.ant-modal-close) {
      background: #ffffff;
      border: 1px solid #dcdce4;
      box-sizing: border-box;
      border-radius: 4px;
      height: 32px;
      width: 32px;
      top: 11px;
      right: 11px;
      :global(.ant-modal-close-x) {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 14px;
      }
    }
    :global(.ant-modal-header) {
      border-bottom: none;
      font-family: 'SFProText Bold';
    }
    &.custom_footer :global(.ant-modal-footer) {
      border-top: none;
    }
  }
  // width: 600px !important;
}
